import React, { useState, useEffect } from "react";

import { MenuWidget } from "./components/MenuWidget";
import {
  getServerApiDietDetails,
  getServerApiMenu,
  getServerApiCompanyParams,
} from "./api/client/companyDetailsApi";
import { customNumSort, getInitialDatesArray } from "./utils";

function App(props) {
  //--------WE NEED THIS TO WORK LOCALLY:----------
  const [data, setData] = useState([]);
  const [firstDietToShowId, setFirstDietToShowId] = useState(0);
  const [showSelect, setShowSelect] = useState(false);
  const [companyId, setCompanyId] = useState("")
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    const { config } = props;
    // console.log('config: ', config)
    if (config) {
      setData(config);
    }
    if (config?.diet) {
      setFirstDietToShowId(Number(config.diet));
    }
    if (config?.showSelect){
      setShowSelect(config.showSelect === 'showSelect')
    }
    if (config?.companyId){
      setCompanyId(config.companyId);
    }
    if (config?.api){
      setApiKey(config.api)
    }
    //WERSJA DEWELOPERSKA LOKALNIE:
    // setCompanyId('crmdev1')
    // setApiKey('1adc2779-69e6-4ccf-84ae-7486b391e36b')
  }, [props, props.config]);

  useEffect(() => {
    document.getElementById("root-widget-menu").removeAttribute("data-config");
  }, [data]);

  useEffect(() => {
    const { primaryColor, secondaryColor } = data;
    if (primaryColor) {
      document.documentElement.style.setProperty("--primary", primaryColor);
    }
    if (secondaryColor) {
      document.documentElement.style.setProperty("--secondary", secondaryColor);
    }
  }, [data]);

  //-------------------------------------------------------------------------
  const [companyDietDetails, setCompanyDietDetails] = useState([]);
  const [companyMenus, setCompanyMenus] = useState([]);
  const [companyName, setCompanyName] = useState("initial");
  const [companyParams, setCompanyParams] = useState([]);

  useEffect(() => {
    const fetchMenus = async () => {
      if(companyId && apiKey) {
        const {config} = props;
        const query = encodeURIComponent(
            JSON.stringify({
              //Inicjalny fetch pobiera dietę wpisaną z palca o dietId=firstDietToShowId
              dietId: Number(config?.diet),
              dates: getInitialDatesArray(),
            })
        );
        try {
          const response = await getServerApiMenu(query, companyId, apiKey);
          setCompanyMenus(response);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchMenus();
  }, [apiKey, companyId, data.id, firstDietToShowId, props]);

  useEffect(() => {
    if(companyId && apiKey){
    getServerApiDietDetails(companyId, apiKey)
      .then((res) => res.json())
      .then((data) => setCompanyDietDetails(customNumSort("priority")(data)))
      .catch((error) => console.log(error));
    }
  },[apiKey, companyId, data]);
  useEffect(() => {
    if(companyId){
      setCompanyName(companyId);
    }
  },[companyId]);
  useEffect(() => {
    if(companyId && apiKey){
    getServerApiCompanyParams(companyId, apiKey)
      .then((res) => res.json())
      .then((data) => setCompanyParams(data))
      .catch((error) => console.log(error));
    }
  },[apiKey, companyId, data]);
  //-------------------------------------------------------------------------
  return (
    <div
      className="dietly-app"
      style={{
        height: data.height + "px" || "initial",
      }}
    >
      {/*
      In order to display image imported from local/static folder we need to set here path (which is full wordpress url)
      to make sure image will display correctly in both prod/dev environments as well as dev/local wordpress setup but
      I highly recommend to use images from our API as I am not sure if images added this way will cover all possible
      configurations and setups.
      (diet images).
      */}
      {/* <img src={(path && path + logo) || logo} alt="" /> */}
      {data && (
        <div className={'dietly-app-1 dietly-app-2 dietly-app-3 dietly-app-4'}>
          <MenuWidget
            companyDietDetails={companyDietDetails}
            companyMenus={companyMenus}
            companyName={companyName}
            companyParams={companyParams}
            path={data.path}
            firstDietId={firstDietToShowId}
            showSelect={showSelect}
            id={companyId}
            api={apiKey}
          />
        </div>
      )}
    </div>
  );
}

export default App;
